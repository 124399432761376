require('../scss/app.scss');

import 'jquery';
import 'popper.js/dist/popper';
import 'bootstrap/dist/js/bootstrap';
import '@fancyapps/fancybox';
// import Vue from 'vue'

// new Vue({
//     el: '#app',
//     data: {
//         message: 'Hello Vue!',
//     },
// });

jQuery('.fancybox').fancybox({

});

$('.block-accordion h3').on('click', function(e){
    e.preventDefault();
    console.log($(this).closest(".item").siblings('.item'));
    $(this).closest(".item").siblings('.item').find('h3.active').toggleClass('active').next('.content').stop(true, true).slideToggle();
    $(this).toggleClass('active').next('.content').stop(true, true).slideToggle();
});